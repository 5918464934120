<template>
  <div class="row">
    <div class="col-12 col-lg-8 c">
    <div class="" style="padding: 0px">
        <div class="alert alert-warning text-center g" v-if="loading" style="margin: 10px;">
            <img width="24" :src="require('@/assets/images/loading.svg')" alt="">
            جاري الإرسال...
        </div>
        <div class="alert alert-danger text-center g" v-if="error" style="margin: 10px;">
            {{ error }}
        </div>
        <div class="alert alert-success text-center g" v-if="success" style="margin: 10px;">
            {{ success }}
        </div>
        <input type="text" class="form-control form-control-lg text-center" id="number" v-model="number" :placeholder="placeholder">
    </div>
    <br>
    <div class="card card-body">
        <div class="col-12">
            <button class="btn btn-sm btn-warning" @click="print()">
                <i class="fa fa-print"></i> طباعة الكشف
            </button>
            <button class="btn btn-sm btn-primary" style="margin-right: 5px" @click="date = ''; gett()">
                <i class="fa fa-calendar"></i> عرض كل الأيام
            </button>
            <input type="date" class="form-control-sm" style="margin-right: 5px" v-model="date" @change="gett()">
        </div>
    </div>
  </div>
  <div class="col-12">
    <div class="card card-body">
        <div class="col-12 table-responsive" id="table" v-if="list.length">
            <table class="table table-sm table-striped table-hover" style="margin-top: 10px">
                <thead>
                    <th>
                        الحاج
                    </th>
                    <th>
                        تاريخ التسجيل
                    </th>
                    <th>
                        تسجيل
                        بواسطة
                    </th>
                    <th>
                        تاريخ التسليم
                    </th>
                    <th>
                        تسليم
                        بواسطة
                    </th>
                </thead>
                <tbody>
                    <tr v-for="l in list" :key="l._id">
                        <td>
                            {{ l.name }}
                        </td>
                        <td>
                            {{ l.register_date }}
                        </td>
                        <td>
                            {{ l.register_by_name }}
                        </td>
                        <td>
                            {{ l.done_date }}
                        </td>
                        <td>
                            {{ l.done_by_name }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
  </div>
  </div>
</template>

<script>
const getDaysArray = function(s,e) {const a=[];for(const d=new Date(s);d<=new Date(e);d.setDate(d.getDate()+1)){ a.push(new Date(d).toISOString().split("T")[0]);}return a;};

export default {
    data(){
        return {
            number: "",
            lastNumber: "",
            user: JSON.parse(localStorage.getItem("user")),
            interval: null,
            loading: false,
            success: null,
            error: null,
            placeholder: "افحص الآن...",
            list: [],
            date: new Date().toISOString().split("T")[0],
            dates: getDaysArray(new Date("2025-01-01"),new Date())
        }
    },
    beforeDestroy(){
        var g = this;
        if(g.interval){
            clearInterval(g.interval)
        }
    },
    created(){
        var g = this;
        if(!checkPer('cards')){
            this.$router.push('/pers')
            return;
        }
        g.interval = setInterval(() => {
            if(g.number != g.lastNumber){
                g.placeholder = g.number;
                g.lastNumber = g.number;
                g.process(g.number)
            }
            $("#number").focus();
        }, 100);
        g.gett()
    },
    methods: {
        print(){
            var g = this;
            var win = window.open()
            win.document.write(`
            <html dir='rtl'>
                <head>
                    <style>
                        table, td, th {
                            border: 1px solid;
                        }

                        table {
                            width: 100%;
                            border-collapse: collapse;
                        }
                        @page{
                            margin: 0px
                        }
                    </style>
                </head>
                <body>
                <h2>كشف تسجيل بطاقات تسليم النسك ${g.date}</h2>
                    ${$("#table").html()}
                </body>
            </html>
            `)
            win.document.close();
            win.print();
            win.close()
        },
        gett(){
            var g = this;
            $.post(api + '/admin/users/cards/dones', {
                jwt: g.user.jwt,
                date: g.date
            }).then(function(r){
                g.list = r.response
            })
        },
        process(number){
            var g = this;
            g.number = "";
            g.lastNumber = "";
            g.loading = true
            g.error = null;
            g.success = null;
            $.post(api + '/admin/users/cards/done', {
                jwt: g.user.jwt,
                number: number
            }).then(function(r){
                g.loading = false;
                if(r.status == 100){
                    g.success = r.response;
                    g.error = null;
                    g.gett()
                }else{
                    g.error = r.response;
                    g.success = null;
                }

            }).fail(function(){
                g.loading = false;
                g.error = "فشل الاتصال، تأكد من اتصالك بالإنترنت";
                g.success = null;
            })
        }
    }
}
</script>

<style>

</style>